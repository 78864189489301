import categoryTree from '@/assets/bundle/categories.json';
import { normalizeTerm } from './utils';

const categoryRoutes = [
    {"id": "8,e0,k1", "term": "WOMEN", "path": "Women", root: true },
    {"id": "8", "term": "Clothing", "path": "Women's Clothing"},
    {"id": "81", "term": "Coats & Jackets", "path": "Women's Coats & Jackets"},
    {"id": "811", "term": "Suits", "path": "Women's Suits"},
    {"id": "812,814,817", "term": "Jackets", "path": "Women's Jackets"},
    {"id": "813,815,816,819", "term": "Coats", "path": "Women's Coats"},
    {"id": "818", "term": "Gilets", "path": "Women's Gilets"},
    {"id": "82", "term": "Tops", "path": "Women's Tops"},
    {"id": "821", "term": "Shirts", "path": "Women's Shirts"},
    {"id": "822", "term": "Hoodies", "path": "Women's Hoodies"},
    {"id": "823", "term": "T-Shirts", "path": "Women's T-Shirts"},
    {"id": "824", "term": "Sweaters", "path": "Women's Sweaters"},
    {"id": "83", "term": "Dresses & Skirts", "path": "Women's Dresses & Skirts"},
    {"id": "834", "term": "Jumpsuits", "path": "Jumpsuits"},
    {"id": "84", "term": "Pants", "path": "Women's Pants"},
    {"id": "841", "term": "Jeans", "path": "Women's Jeans"},
    {"id": "842", "term": "Joggers", "path": "Women's Joggers"},
    {"id": "843", "term": "Leggings", "path": "Women's Leggings"},
    {"id": "845", "term": "Shorts", "path": "Women's Shorts"},
    {"id": "85", "term": "Lingerie", "path": "Women's Lingerie"},
    {"id": "852", "term": "Sports Bras", "path": "Sports Bras"},
    {"id": "853", "term": "Underwear", "path": "Women's Underwear"},
    {"id": "e0", "term": "Bags", "path": "Women's Bags"},
    {"id": "e04", "term": "Handbags", "path": "Handbags"},
    {"id": "e06", "term": "Backpacks", "path": "Women's Backpacks"},
    {"id": "e07", "term": "Tote Bags", "path": "Tote Bags"},
    {"id": "e08", "term": "Crossbody Bags", "path": "Crossbody Bags"},
    {"id": "e09", "term": "Wallets", "path": "Wallets"},
    {"id": "e1", "term": "Bags", "path": "Men's Bags"},
    {"id": "e13", "term": "Handbags", "path": "Men's Handbags"},
    {"id": "e14", "term": "Shoulder Bags", "path": "Men's Shoulder Bags"},
    {"id": "e15", "term": "Backpacks", "path": "Men's Backpacks"},
    {"id": "e17", "term": "Wallets", "path": "Men's Wallets"},
    {"id": "e2", "term": "Luggage & Travel Gear", "path": "Luggage & Travel Gear"},
    {"id": "e21", "term": "Backpacks", "path": "Backpacks"},
    {"id": "e22", "term": "Hiking Bags", "path": "Hiking Bags"},
    {"id": "e23", "term": "Travel Bags", "path": "Travel Bags"},
    {"id": "e24", "term": "Luggage", "path": "Luggage"},
    {"id": "e26", "term": "Briefcases", "path": "Briefcases"},
    {"id": "k1", "term": "Shoes", "path": "Women's Shoes"},
    {"id": "k10", "term": "Boots", "path": "Women's Boots"},
    {"id": "k11", "term": "Espadrilles", "path": "Women's Espadrilles"},
    {"id": "k13", "term": "Wedge Sandals", "path": "Women's Wedge Sandals"},
    {"id": "k17", "term": "Snow Boots", "path": "Women's Snow Boots"},
    {"id": "k18", "term": "Slippers", "path": "Women's Slippers"},
    {"id": "k1a", "term": "Sandals", "path": "Women's Sandals"},
    {"id": "k1b", "term": "Casual Shoes", "path": "Women's Casual Shoes"},
    {"id": "k1e", "term": "Ankle Boots", "path": "Women's Ankle Boots"},
    {"id": "k1g", "term": "High Heels", "path": "Women's High Heels"},
    {"id": "k1k", "term": "Rain Boots", "path": "Women's Rain Boots"},
    {"id": "86", "term": "Accessories", "path": "Women's Accessories"},
    {"id": "869", "term": "Belts", "path": "Women's Belts"},
    {"id": "864", "term": "Watches", "path": "Women's Digital Watches"},
    {"id": "865", "term": "Women's Quartz Watches", "path": "Women's Quartz Watches"},
    {"id": "866", "term": "Women's Automatic Watches", "path": "Women's Automatic Watches"},
    {"id": "868", "term": "Gloves", "path": "Women's Gloves"},
    {"id": "867", "term": "Hats", "path": "Women's Hats"},
    {"id": "860", "term": "Scarves", "path": "Women's Scarves"},
    {"id": "863", "term": "Sunglasses", "path": "Women's Sunglasses"},
    {"id": "9,e1,k0", "term": "MEN", "path": "Men", root: true},
    {"id": "9", "term": "Clothing", "path": "Men's Clothing"},
    {"id": "91", "term": "Coats & Jackets", "path": "Men's Coats & Jackets"},
    {"id": "910", "term": "Suits", "path": "Men's Suits"},
    {"id": "911,913,916", "term": "Jackets", "path": "Men's Jackets"},
    {"id": "912,914,915,918", "term": "Coats", "path": "Men's Coats"},
    {"id": "917", "term": "Gilets", "path": "Men's Gilets"},
    {"id": "92", "term": "Tops", "path": "Men's Tops"},
    {"id": "920", "term": "Shirts", "path": "Men's Shirts"},
    {"id": "921", "term": "Hoodies", "path": "Men's Hoodies"},
    {"id": "922", "term": "T-Shirts", "path": "Men's T-Shirts"},
    {"id": "923", "term": "Sweaters", "path": "Men's Sweaters"},
    {"id": "925", "term": "Polo Shirts", "path": "Men's Polo Shirts"},
    {"id": "93", "term": "Pants", "path": "Men's Pants"},
    {"id": "930", "term": "Jeans", "path": "Men's Jeans"},
    {"id": "931", "term": "Joggers", "path": "Men's Joggers"},
    {"id": "933", "term": "Shorts", "path": "Men's Shorts"},
    {"id": "94", "term": "Sleep & Underwear", "path": "Men's Sleep & Underwear"},
    {"id": "943", "term": "Underwear", "path": "Men's Underwear"},
    {"id": "k0", "term": "Shoes", "path": "Men's Shoes"},
    {"id": "k04", "term": "Sandals", "path": "Men's Sandals"},
    {"id": "k05", "term": "Boots", "path": "Men's Boots"},
    {"id": "k07", "term": "Dress Shoes", "path": "Men's Dress Shoes"},
    {"id": "k08", "term": "Business Casual Shoes", "path": "Men's Business Casual Shoes"},
    {"id": "k09", "term": "Casual Shoes", "path": "Men's Casual Shoes"},
    {"id": "95", "term": "Accessories", "path": "Men's Accessories"},
    {"id": "959", "term": "Belts", "path": "Men's Belts"},
    {"id": "95a", "term": "Ties", "path": "Men's Ties"},
    {"id": "958", "term": "Gloves", "path": "Men's Gloves"},
    {"id": "957", "term": "Hats", "path": "Men's Hats"},
    {"id": "956", "term": "Men's Automatic Watches", "path": "Men's Automatic Watches"},
    {"id": "954", "term": "Men's Quartz Watches", "path": "Men's Quartz Watches"},
    {"id": "955", "term": "Watches", "path": "Men's Digital Watches"},
    {"id": "953", "term": "Sunglasses", "path": "Men's Sunglasses"},
    {"id": "95b", "term": "Other", "path": "Men's Other"},
    {"id": "b", "term": "BEAUTY", "path": "BEAUTY"},
    {"id": "b0", "term": "Cosmetics", "path": "Cosmetics"},
    {"id": "b1", "term": "Cosmetic Tools", "path": "Cosmetic Tools"},
    {"id": "b2", "term": "Facial Skincare", "path": "Facial Skincare"},
    {"id": "b3", "term": "Hair Care", "path": "Hair Care"},
    {"id": "b4", "term": "Body Care", "path": "Body Care"},
    {"id": "b5", "term": "Men's Beauty & Personal Care", "path": "Men's Beauty & Personal Care"},
    {"id": "7", "term": "SPORTS & OUTDOOR", "path": "Sports & Outdoor"},
    {"id": "701", "term": "Camping Tents", "path": "Camping Tents"},
    {"id": "702", "term": "Sleeping Bags", "path": "Sleeping Bags"},
    {"id": "70b", "term": "Ski Gear", "path": "Ski Gear"},
    {"id": "70g", "term": "Ski Goggles", "path": "Ski Goggles"},
    {"id": "751", "term": "Sneakers", "path": "Men's Sneakers"},
    {"id": "752", "term": "Basketball Shoes", "path": "Men's Basketball Shoes"},
    {"id": "755", "term": "Soccer Shoes", "path": "Men's Soccer Shoes"},
    {"id": "778", "term": "Skateboarding & Rollerblading", "path": "Skateboarding & Rollerblading"},
    {"id": "77b", "term": "Sports Gloves", "path": "Sports Gloves"},
    {"id": "78", "term": "Outdoor", "path": "Outdoor"},
    {"id": "789", "term": "Hiking Shoes", "path": "Hiking Shoes"},
    {"id": "753,7e2", "term": "Running Shoes", "path": "Running Shoes"},
    {"id": "754", "term": "Canvas Shoes", "path": "Men's Canvas Shoes"},
    {"id": "7c0,7c2,7c4", "term": "Swimming Gear", "path": "Swimming Gear"},
    {"id": "7c1,7c3,g82,gbk", "term": "Swimwear", "path": "Swimwear"},
    {"id": "7d", "term": "Yoga", "path": "Yoga"},
    {"id": "7d1,7d3", "term": "Yoga Mats & Exercise Balls", "path": "Yoga Mats & Exercise Balls"},
    {"id": "7e", "term": "Sports Shoes", "path": "Sports Shoes"},
    {"id": "7e0", "term": "Sneakers", "path": "Women's Sneakers"},
    {"id": "7e1", "term": "Basketball Shoes", "path": "Women's Basketball Shoes"},
    {"id": "7e6", "term": "Soccer Shoes", "path": "Women's Soccer Shoes"},
    {"id": "a3a", "term": "Smart Sunglasses", "path": "Smart Sunglasses"},
    {"id": "f", "term": "JEWELRY", "path": "Jewelry"},
    {"id": "f05", "term": "Earrings", "path": "Earrings"},
    {"id": "f03", "term": "Bracelets", "path": "Bracelets"},
    {"id": "f00", "term": "Necklaces", "path": "Necklaces"},
    {"id": "f04", "term": "Rings", "path": "Rings"},
    {"id": "f6", "term": "Fashion Accessories", "path": "Fashion Accessories"},
    {"id": "i34", "term": "Water Bottles", "path": "Water Bottles"},
    {"id": "g", "term": "BABY & KIDS", "path": "BABY & KIDS"},
    {"id": "gbb,gbc,gbd,gbe,gbf,gbg,gbh,gbi,gbj,gbk,gbl,gbm,gbn,gbo,gbp", "term": "Baby Clothing", "path": "Baby Clothing"},
    {"id": "gb0,gb1,gb2,gb3,gb4,gb5,gb6,gb7,gbq", "term": "Boy's Shoes", "path": "Boy's Shoes"},
    {"id": "g8", "term": "Kid's Clothing", "path": "Kid's Clothing"}
]

function findCategoryRouteById(cid?: string, contains = false) {
    if (!cid) return null
    return categoryRoutes.find(c => {
        if (c.id === cid) {
            return true
        }
        // 如果正好是3级分类，可以从这里满足条件包含的多分类页面中找到适合的路由
        if (!!contains && c.id.indexOf(',') !== -1 && cid.length === 3) {
            return c.id.indexOf(cid) !== -1
        }
        return false
    })
}

function findCategoryRouteByPath(path?: string) {
    if (!path) return null
    return categoryRoutes.find(c => normalizeTerm(c.path) === path)
}

function findCategoryPath(cid?: string): string {
    const route = findCategoryRouteById(cid)
    if (route != null) {
        return `/category/${normalizeTerm(route.path)}`
    }
    return ''
}

const WOMEN = {
    id: "8,e0,k1",
    children: [
        {
            id: "8",
            children: [
            { id: "813,815,816,819" }, // Coats
            { id: "82" }, // Tops
            { id: "824" }, //Sweaters
            { id: "83" }, // Dresses & Skirts
            { id: "84" }, // Pants
            { id: "85" }  // Lingerie
            ]
        },
        {
            id: "e0",
            children: [
            { id: "e04" }, // Handbags
            { id: "e06" }, // Backpacks
            { id: "e07" }, // Tote Bags
            { id: "e08" }, // Crossbody Bags
            { id: "e09" }, // Wallets
            { id: "e24" }, // Luggage
            ]
        },
        {
            id: "k1",
            children: [
            { id: "k10" }, // Boots
            { id: "k1a" }, // Sandals
            { id: "k18" }, // Slippers
            { id: "k1e" }, // Ankle Boots
            { id: "k1g" }, // High Heels
            { id: "k1k" }  // Rain Boots
            ]
        },
        {
            id: "86",
            children: [
            { id: "869" }, // Belts
            { id: "864" }, // Watches
            { id: "868" }, // Gloves
            { id: "867" }, // Hats
            { id: "860" }, // Scarves
            { id: "863" }  // Sunglasses
            ]
        }
    ]
};

const MEN = {
    id: "9,e1,k0",
    children: [
        {
            id: "9",
            children: [
            { id: "910" }, // Suits
            { id: "912,914,915,918" }, // Coats
            { id: "911,913,916" }, // Jackets
            { id: "92" }, // Tops
            { id: "920" }, // Shirts
            { id: "921" }, // Hoodies
            { id: "93" }, // Pants
            { id: "94" }  // Sleep & Underwear
            ]
        },
        {
            id: "e1",
            children: [
            { id: "e14" }, // Shoulder Bags
            { id: "e15" }, // Backpacks
            { id: "e17" }, // Wallets
            { id: "e26" }, // Briefcases
            ]
        },
        {
            id: "k0",
            children: [
            { id: "751" }, // Sneakers
            { id: "k05" }, // Boots
            { id: "k04" }, // Sandals, Slippers
            { id: "k07" }, // Dress Shoes
            { id: "k09" },  // Casual Shoes
            { id: "754" }, // Canvas Shoes
            ]
        },
        {
            id: "95",
            children: [
            { id: "959" }, // Belts
            { id: "95a" }, // Ties
            { id: "955" }, // Watches
            { id: "958" }, // Gloves
            { id: "957" }, // Hats
            { id: "953" }, // Sunglasses
            ]
        }
    ]
};

const BEAUTY = {
    id: "b",
    children: [
    { id: "b0" }, // Cosmetics
    { id: "b1" }, // Cosmetic Tools
    { id: "b2" }, // Facial Skincare
    { id: "b3" }, // Hair Care
    { id: "b4" }, // Body Care
    { id: "b5" }  // Men's Beauty & Personal Care
    ]
}

const SPORTS_OUTDOOR = {
    id: "7",
    children: [
    { id: "7e" },  // Sports Shoes
    { id: "753,7e2" }, // Running Shoes
    { id: "7d" },   // Yoga 
    { id: "78" },  // Outdoor
    { id: "e23" },   // Travel Bags
    { id: "7c1,7c3,g82,gbk" },  // Swimwear
    ]
}

const JEWELRY = {
    id: "f",
    children: [
    { id: "f05" }, // Earrings
    { id: "f03" }, // Bracelets
    { id: "f00" }, // Necklaces
    { id: "f04" }  // Rings
    ]
}

const BABY = {
    id: "g",
    children: [
    { id: "gbb,gbc,gbd,gbe,gbf,gbg,gbh,gbi,gbj,gbk,gbl,gbm,gbn,gbo,gbp" }, // Baby Clothing
    { id: "gb0,gb1,gb2,gb3,gb4,gb5,gb6,gb7,gbq" }, // Boy's Shoes
    { id: "g8" }  // Kid's Clothing
    ]
}

const PRE_LOVED = { 
    "query": "l=pre_owned", 
    "term": "PRE-LOVED",
    children: [
        { "query": "l=pre_owned&cids=e", "term": "PRE-LOVED Bags" },
        { "query": "l=pre_owned&cids=k1", "term": "PRE-LOVED Women's Shoes" },
        { "query": "l=pre_owned&cids=k0", "term": "PRE-LOVED Men's Shoes" },
        { "query": "l=pre_owned&cids=8", "term": "PRE-LOVED Women's Clothing" },
        { "query": "l=pre_owned&cids=9", "term": "PRE-LOVED Men's Clothing" },
    ]
}

function findCategoryInTree(categoryId: string): any {
    function searchNode(node: any): any {
        if (node.category?.id === categoryId) {
            return node
        }
        if (node.children?.length) {
            for (const child of node.children) {
                const result = searchNode(child)
                if (result) return result
            }
        }
        return null
    }

    for (const category of categoryTree.children) {
        const result = searchNode(category)
        if (result) return result
    }
    return null
}

export function getCategoryPath(id?: string, withHome: boolean = false) {
    const categoryId = id?.trim()
    if (!categoryId) return []
    const result: any[] = []
    try {
        if (categoryId.length > 1) {
            let nextId = categoryId.slice(0, -1)
            if (categoryId.indexOf(',') !== -1) {
                nextId = categoryId.split(',')?.[0]?.slice(0, -1)
            }
            const res = getCategoryPath(nextId, withHome)
            if (!!res?.length) {
                result.push(...res)
            }
        }

        if (withHome) {
            const route = findCategoryRouteById(categoryId, true)
            if (!!route) {
                result.push(route)
            }
        } else {
            const node = findCategoryInTree(categoryId);
            if (!!node) {
                result.push(node.category);
            }
        }
    } catch (_) {}
    return result
}

export function groupCategoriesByLetter() {
    // Create a map to store categories by first letter
    const groupedCategories: { [key: string]: typeof categoryRoutes } = {};
    
    // Group categories by first letter of path
    categoryRoutes.forEach(category => {
        const firstLetter = category.path.charAt(0).toUpperCase();
        if (!groupedCategories[firstLetter]) {
            groupedCategories[firstLetter] = [];
        }
        groupedCategories[firstLetter].push(category);
    });

    // Sort categories within each letter group
    Object.keys(groupedCategories).forEach(letter => {
        groupedCategories[letter].sort((a, b) => a.path.localeCompare(b.path));
    });

    // Get all letters in alphabetical order
    const letters = Object.keys(groupedCategories).sort();

    return {
        groupedCategories,
        letters
    };
}

export function getRecommendCategories(categoryId: string) {
    if (!categoryId) return [];
    const currentCategory = findCategoryRouteById(categoryId, true);
    if (!currentCategory) return [];
    
    // assume no comma in categoryId
    const level = categoryId.length;
    let parentId = '';
    if (level === 2) {
        parentId = categoryId.charAt(0);
    } else if (level === 3) {
        parentId = categoryId.slice(0, 2);
    }
    
    // For level 2 categories, first try to get all its level 3 children
    if (level === 2) {
        const childCategories = categoryRoutes.filter(category => {
            if (category.root) return false;
            if (category.id === categoryId) return false;
            
            const catId = category.id;
            if (catId.includes(',')) {
                const idParts = catId.split(',');
                return idParts.some(part => part.startsWith(categoryId) && part.length === 3);
            }
            
            return catId.startsWith(categoryId) && catId.length === 3;
        });
        
        // If we found level 3 children, return them
        if (childCategories.length > 0) {
            return childCategories.slice(0, 10);
        }
    }
    
    return categoryRoutes.filter(category => {
        if (category.root) return false;
        if (category.id === categoryId) return false;
        
        const catId = category.id;
        if (catId.includes(',')) {
            const idParts = catId.split(',');
            
            // For level 1: Include if any part starts with the categoryId
            if (level === 1) {
                return idParts.some(part => part.startsWith(categoryId));
            }
            
            // For level 2 & 3: Include if any part has the same parent
            if (level >= 2) {
                return idParts.some(part => {
                    if (level === 2) return part.charAt(0) === parentId;
                    if (level === 3) return part.slice(0, 2) === parentId;
                    return false;
                });
            }
        }
        if (level === 1) {
            return catId.startsWith(categoryId) && catId.length === 2;
        }
        if (level === 2) {
            return catId.charAt(0) === parentId && catId.length === 2;
        }
        if (level === 3) {
            return catId.slice(0, 2) === parentId && catId.length === 3;
        }
        return false;
    }).slice(0, 10);
}

const categoryShortTermsMappings: {
    exact: { [key: string]: string },
    prefix: { [key: string]: string },
    root: { [key: string]: string }
} = {
  // Exact matches (highest priority)
  exact: {
    '751': 'Sneakers',
    '7e0': 'Sneakers',
    // Shirts
    '821': 'Shirts',
    '920': 'Shirts',
    // T-Shirts
    '823': 'T-Shirts',
    '922': 'T-Shirts',
    // Sunglasses
    '863': 'Sunglasses',
    '953': 'Sunglasses',
    // Watches
    '864': 'Watches',
    '865': 'Watches',
    '866': 'Watches',
    '954': 'Watches',
    '955': 'Watches',
    '956': 'Watches',
    // Bags
    'e04': 'Handbags',
    'e13': 'Handbags',
    'e05': 'Shoulder Bag',
    'e14': 'Shoulder Bag',
    'e07': 'Tote Bag',
    'e06': 'Backpacks',
    'e15': 'Backpacks',
    // Shoes
    'k13': 'Sandals',
    'k1a': 'Sandals',
    'k10': 'Boots',
    'k17': 'Boots',
    'k18': 'Slippers',
    'k1e': 'Boots',
    'k1k': 'Boots',
    'k04': 'Slippers',
    'k05': 'Boots',
    'k0a': 'Boots',
    'k0b': 'Boots',
  },
  // Prefix matches (second priority)
  prefix: {
    '70': 'Outdoor Gear',
    '75': 'Shoes',
    '7a': 'Cycling Gear',
    '7b': 'Fishing Gear',
    '7c': 'Swim Gear',
    '7d': 'Yoga Clothes',
    '7e': 'Shoes',
    '82': 'Tops',
    '92': 'Tops',
    '83': 'Dresses',
    '93': 'Pants',
    '84': 'Pants',
    '95': 'Accessories',
    '86': 'Accessories',
  },
  // Root matches (lowest priority)
  root: {
    '8': 'Clothing',
    '9': 'Clothing',
    'e': 'Bags',
    'k': 'Shoes',
    'b': 'Beauty',
    'f': 'Jewelry',
  }
};

function mapCategoryIdToShortTerm(categoryId: string): string | undefined {
  // Check for exact match (highest priority)
  if (categoryShortTermsMappings.exact[categoryId]) {
    return categoryShortTermsMappings.exact[categoryId];
  }
  
  // Check for prefix matches (medium priority)
  for (const [prefix, term] of Object.entries(categoryShortTermsMappings.prefix)) {
    if (categoryId.startsWith(prefix)) {
      return term;
    }
  }
  
  // Check for root matches (lowest priority)
  const rootChar = categoryId.charAt(0);
  return categoryShortTermsMappings.root[rootChar];
}

function useInferCategoryTerms(products: any) {
    if (!products?.length) return []
    try {
        // at most top 30 items
        const categoryIds = products.slice(0, 30).map((product: any) => product.categoryIds).flat();
        const terms = categoryIds.map((id: string) => mapCategoryIdToShortTerm(id)).filter(Boolean);
        const termCounts = terms.reduce((acc: any, term: string) => {
            acc[term] = (acc[term] || 0) + 1;
            return acc;
        }, {});
        const sortedTerms = Object.entries(termCounts)
            .sort((a: any, b: any) => b[1] - a[1])
            .map(([term, count]) => ({ term, count })).filter((item: any) => item.count > 3);
        // console.log(sortedTerms)
        return sortedTerms
    } catch (e) {} 
    return []
}

function formatCategoryTerms(categoryTerms?: { term: string, count: number }[]): string {
    if (!categoryTerms?.length) return '';
    // Take top 3 terms
    const topTerms = categoryTerms.slice(0, 3).map(item => item.term);
    
    if (topTerms.length === 1) {
        return topTerms[0];
    } else if (topTerms.length === 2) {
        return `${topTerms[0]} & ${topTerms[1]}`;
    } else if (topTerms.length === 3) {
        if (categoryTerms.length > 3) {
            return `${topTerms[0]}, ${topTerms[1]}, ${topTerms[2]} & more`;
        } else {
            return `${topTerms[0]}, ${topTerms[1]} & ${topTerms[2]}`;
        }
    }
    return '';
}

export {
    categoryRoutes,
    WOMEN,
    MEN,
    BEAUTY,
    SPORTS_OUTDOOR,
    JEWELRY,
    BABY,
    PRE_LOVED,
    findCategoryRouteById,
    findCategoryRouteByPath,
    findCategoryPath,
    useInferCategoryTerms,
    formatCategoryTerms,
}