import React, { useState, useEffect, useRef } from 'react';
import * as headers from './header.module.scss';
import LogoBlack from '@images/ic_logo_black.svg';
import LogoWhite from '@images/ic_logo_white.svg';
import LogoB from '@images/logo_b.svg';
import { Input } from 'antd-mobile';
import iconSearch from '@images/icon_search_gray.svg';
import iconProfile from '@images/icon_profile.png';
import iconProfileWhite from '@images/icon_profile_white.png';
import { getHotWords } from '@service/api';
import { getAccount, isLogin } from '@service/AccountService';
import Profile from './Profile';
import Department from './Department';
import SearchPanel from '@/components/searchPanel';
import useStickyHeader from '@/hooks/use-sticky-header'
import { isBrowser } from '@lib/utils';
import useTips from '@/hooks/use-tips'
import { trackEvent } from '@/lib/analytics';

const pageClassMap = new Map([
  ['HOME', headers.pHome], 
  ['OUTLET', headers.pOutlets], 
  ['ECO', headers.pEco],
  ['CATEGORY', headers.pCategory], 
  ['EURO', headers.pEuro],
  ['LOWEST_PRICE', headers.pLowestPrice],
  ['SPORTS_OUTDOORS', headers.pSportsOutdoors],
  ['TOP_SELLING', headers.pTopSelling]
])

const SignIn_Placeholder = 'Sign in'

const Header = (props) => {
  const { pageName, query, updateQuery, pathname }  = props || {}

  const rightRef = useRef(null)
  const [value, setValue] = useState(query || '')
  const [isFocus, changeFocus] = useState(false)
  const [profileVisible, setProfileVisible] = useState()
  const account = getAccount()
  const { firstName } = account || {}
  const [hotWords, setHotWords] = useState([])
  const isCheckout = (pathname.indexOf('/checkout/') !== -1)
  const [loginInfo, setLoginInfo] = useState(SignIn_Placeholder)
  const [tipPage, onPageVisible] = useTips(pageName)
  const isWhiteTheme = pageName === "TOP_SELLING"

  useEffect(() => {
    if (!isFocus) return
    getHotWords()
      .then((res) => {
        setHotWords(res.hotWords)
      })
      .catch((err) => {
        // console.warn(err)
      })
  }, [isFocus])

  useEffect(() => {
    if (isFocus || profileVisible) {
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.height = '100vh'
    } else {
      document.documentElement.style.overflow = 'visible'
      document.documentElement.style.height = 'auto'
    }
  }, [isFocus, profileVisible])

  const doSearch = () => {
    changeFocus(false)
    setValue(value)
    updateQuery(value)

    trackEvent('search_action', {
      event_category: 'search',
      event_action: 'search_input',
      search_term: value,
    })
  }

  const getPageClass = () => {
    return pageClassMap.get(pageName) || ''
  }

  const onClickHotWordsItem = (item) => {
    changeFocus(false)
    setValue(item)
    updateQuery(item)

    trackEvent('search_action', {
      event_category: 'search',
      event_action: 'hot_words',
      search_term: item,
    })
  }

  const profileClick = () => {
    if(isLogin()) {
      setProfileVisible(true)
    } else {
      window.location.href = '/login'
    }
  }

  useEffect(() => {
    if (!isBrowser) {
      return
    }
    if (isLogin()) {
      setLoginInfo(firstName ? `Hi ${firstName}` : SignIn_Placeholder)
    } else {
      setLoginInfo(SignIn_Placeholder)
    }
  }, [firstName])

  const [fixed, headerRef] = useStickyHeader()
  return (
    <header id='header_pc' className={getPageClass()} ref={headerRef}>
      <div className={`${headers.headerPc} ${fixed ? headers.fixed : ''} ${isWhiteTheme ? headers.white : ''}`}>
        <div className={headers.headerPcLeft}>
          {isCheckout ? (
            <div className={headers.checkoutHeaderTitle}><img src={LogoB} />Check Out</div>
          ) : (
            <div><a href='/' title='homepage'><img src={isWhiteTheme ? LogoWhite : LogoBlack} alt="logo" /></a></div>
          )}
          {!isCheckout ? (
            <ol className={headers.headerNav}>
              <li><a href='/outlet/' title='b-outlet' className={pageName === 'OUTLET' ? headers.activeRoute : ''}>B-OUTLET</a></li>
              <li><a href='/euro/' title='b-euro' className={pageName === 'EURO' ? headers.activeRoute : ''}>B-EURO</a></li>
              <li>
                { tipPage === "SPORTS_OUTDOORS" && <div className={headers.newTips}>NEW</div> }
                <a onClick={() => onPageVisible("SPORTS_OUTDOORS")} href='/sports-outdoors/' title='sports & outdoors' className={pageName === 'SPORTS_OUTDOORS' ? headers.activeRoute : ''}>SPORTS & OUTDOORS</a></li>
              <li>
                {tipPage === "TOP_SELLING" && <div className={headers.newTips}>NEW</div>}
                <a
                    href='/top-selling/'
                    title='top-selling'
                    className={pageName === 'TOP_SELLING' ? headers.activeRoute : ''}
                    onClick={() => onPageVisible("TOP_SELLING")}
                >TOP SELLING</a>
              </li>
              <li>
                {tipPage === "LOWEST_PRICE" && <div className={headers.newTips}>NEW</div>}
                <a
                    href='/lowest-price/'
                    title='lowest-price'
                    className={pageName === 'LOWEST_PRICE' ? headers.activeRoute : ''}
                    onClick={() => onPageVisible("LOWEST_PRICE")}
                >LOWEST PRICE</a>
              </li>
              <li>
                <div className={headers.pDepartment}>
                  <span>DEPARTMENTS</span>
                  <div className={headers.department}>
                    <Department />
                  </div>
                </div>
              </li>
            </ol>
          ) : null}
        </div>
        <div className={headers.headerPcRight} ref={rightRef}>
          {!isCheckout ? (
            <div className={headers.pSearchMain}>
              <form className={headers.pForm} target={'frameFile'} onSubmit={doSearch}>
                <Input
                  placeholder={`Search for any product or brand`}
                  value={value}
                  className={headers.searchInput}
                  onFocus={() => changeFocus(true)}
                  onBlur={() => changeFocus(false)}
                  onChange={val => {
                    setValue(val)
                  }}
                />
                <iframe title='search' name='frameFile' style={{ display: 'none' }}></iframe>
              </form>
              <div className={headers.pIconSearch} onClick={doSearch}>
                <img src={iconSearch} alt={'icon search'} />
              </div>
            </div>
          ) : null}
          <div className={headers.headerProfile} onClick={profileClick}>
            <img src={isWhiteTheme ? iconProfileWhite : iconProfile} alt="icon profile" />
            <div>{loginInfo}</div>
          </div>
        </div>
        {
          isFocus ? <div className={headers.headerSearchMask} onClick={() => changeFocus(false)}></div> : <></>
        }
        {
          isFocus ? (
            <SearchPanel
              title='Popular' 
              items={hotWords}
              onClickItem={(_, item) => onClickHotWordsItem(item)}
              left={rightRef.current?.offsetLeft - rightRef.current?.clientWidth * 0.5}
              top={rightRef.current?.clientHeight}
              zIndex={999}
            />
          ) : null
        }
      </div>
      <Profile visible={profileVisible} close={() => setProfileVisible(false)} />
    </header>
  )
}

export default Header