export const isProd = true;
export const BXL_USER_ID = "BXL_USER_ID";
export const BXL_USER_KEY = "BXL_USER_KEY";
export const MODE = {
  ALL: 'ALL',
  OUTLET: 'OUTLET',
  ECO: 'ECO',
  LUXURY: 'LUXURY',
  EURO: 'EUROPEAN'
}
export const REGION = {
  US: 'US',
  EUROPEAN: 'EUROPEAN',
  OTHERS: 'OTHERS'
}

export const sortByMap = new Map([
  ["Our Recommendation", null],
  ["Best Sellers", 'sales'],
  ["Newest", 'recency'],
  ["Price (High - Low)", 'priceDescTag'],
  ["Price (Low - High)", 'priceAscTag'],
  ["Discount (Low - High)", 'discountAsc'],
  ["Discount (High - Low)", 'discount'],
])

export const sortByReverseMap = new Map([
  ['sales', "Best Sellers"],
  ["recency", 'Newest'],
  ['priceDescTag', "Price (High - Low)"],
  ['priceAscTag', "Price (Low - High)"],
  ['discountAsc', "Discount (Low - High)"],
  ['discount', "Discount (High - Low)"],
])

// 'Under $50' | '$50 - $100' | '$100 - $200' | '$200 - $300' | '$300 - $500' | '$500 & Above'
export const priceRangeMap = new Map([
  ['Under $50', '0-5000'],
  ['$50 - $100', '5000-10000'],
  ['$100 - $200', '10000-20000'],
  ['$200 - $300', '20000-30000'],
  ['$300 - $400', '30000-50000'],
  ['$400 & Above', '40000-'],
])

export const priceRangeReverseMap = new Map([
  ['0-5000', 'Under $50'],
  ['5000-10000', '$50 - $100'],
  ['10000-20000', '$100 - $200'],
  ['20000-30000', '$200 - $300'],
  ['30000-40000', '$300 - $400'],
  ['40000-', '$400 & Above'],
])

export const ModeData = [{
  key: MODE.ALL,
  name: 'ALL',
  num: 0
}, {
  key: MODE.OUTLET,
  name: 'B-OUTLET',
  num: 0
}, {
  key: MODE.EURO,
  name: 'B-EURO',
  num: 0
}, {
  key: MODE.ECO,
  name: 'B-ECO',
  num: 0
}];

export const ModeMerchantDataPC = [{
  key: MODE.ALL,
  name: 'ALL MERCHANTS',
  num: 0
}, {
  key: MODE.OUTLET,
  name: 'B-OUTLET',
  num: 0
}, {
  key: MODE.EURO,
  name: 'B-EURO',
  num: 0
}, {
  key: MODE.ECO,
  name: 'B-ECO',
  num: 0
}];

export const ModeBrandDataPC = [{
  key: MODE.ALL,
  name: 'ALL BRANDS',
  num: 0
}, {
  key: MODE.OUTLET,
  name: 'B-OUTLET',
  num: 0
}, {
  key: MODE.ECO,
  name: 'B-ECO',
  num: 0
}];

export const BSU_ACCOUNT = 'BSU_ACCOUNT';
export const BSU_SECRET = 'BSU_SECRET';

export const MODE_NAME = {
  [MODE.ALL]: 'ALL',
  [MODE.OUTLET]: 'B-OUTLET',
  [MODE.LUXURY]: 'B-LUX',
  [MODE.ECO]: 'B-ECO'
}

export const PREV_PAGE = "PREV_PAGE"
export const PRODUCT_PAGE = "PRODUCT_PAGE"
export const CATEGORY_PAGE = "CATEGORY_PAGE"

export const SizeIdTypeMap: any = {
  'men_shoes': "men's shoes",
  'women_shoes': "women's shoes",
}

export const SizeTypeIdMap: any = {
  "men's shoes": 'men_shoes',
  "women's shoes": 'women_shoes',
}