exports.components = {
  "component---src-pages-500-index-tsx": () => import("./../../../src/pages/500/index.tsx" /* webpackChunkName: "component---src-pages-500-index-tsx" */),
  "component---src-pages-article-id-tsx": () => import("./../../../src/pages/article/[...id].tsx" /* webpackChunkName: "component---src-pages-article-id-tsx" */),
  "component---src-pages-brand-collection-index-tsx": () => import("./../../../src/pages/brand/[...collection]/index.tsx" /* webpackChunkName: "component---src-pages-brand-collection-index-tsx" */),
  "component---src-pages-c-norm-tsx": () => import("./../../../src/pages/c/[...norm].tsx" /* webpackChunkName: "component---src-pages-c-norm-tsx" */),
  "component---src-pages-category-name-tsx": () => import("./../../../src/pages/category/[...name].tsx" /* webpackChunkName: "component---src-pages-category-name-tsx" */),
  "component---src-pages-deal-title-tsx": () => import("./../../../src/pages/deal/[...title].tsx" /* webpackChunkName: "component---src-pages-deal-title-tsx" */),
  "component---src-pages-prod-title-tsx": () => import("./../../../src/pages/prod/[...title].tsx" /* webpackChunkName: "component---src-pages-prod-title-tsx" */),
  "component---src-pages-s-keyword-tsx": () => import("./../../../src/pages/s/[...keyword].tsx" /* webpackChunkName: "component---src-pages-s-keyword-tsx" */),
  "component---src-pages-store-collection-index-tsx": () => import("./../../../src/pages/store/[...collection]/index.tsx" /* webpackChunkName: "component---src-pages-store-collection-index-tsx" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-brand-list-js": () => import("./../../../src/templates/brand-list.js" /* webpackChunkName: "component---src-templates-brand-list-js" */),
  "component---src-templates-categories-index-tsx": () => import("./../../../src/templates/categories/index.tsx" /* webpackChunkName: "component---src-templates-categories-index-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-checkout-tsx": () => import("./../../../src/templates/checkout.tsx" /* webpackChunkName: "component---src-templates-checkout-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-deals-index-tsx": () => import("./../../../src/templates/deals/index.tsx" /* webpackChunkName: "component---src-templates-deals-index-tsx" */),
  "component---src-templates-doc-aboutus-js": () => import("./../../../src/templates/doc/aboutus.js" /* webpackChunkName: "component---src-templates-doc-aboutus-js" */),
  "component---src-templates-doc-affiliates-js": () => import("./../../../src/templates/doc/affiliates.js" /* webpackChunkName: "component---src-templates-doc-affiliates-js" */),
  "component---src-templates-doc-contactus-js": () => import("./../../../src/templates/doc/contactus.js" /* webpackChunkName: "component---src-templates-doc-contactus-js" */),
  "component---src-templates-doc-cookies-js": () => import("./../../../src/templates/doc/cookies.js" /* webpackChunkName: "component---src-templates-doc-cookies-js" */),
  "component---src-templates-doc-disclosure-js": () => import("./../../../src/templates/doc/disclosure.js" /* webpackChunkName: "component---src-templates-doc-disclosure-js" */),
  "component---src-templates-doc-faqs-js": () => import("./../../../src/templates/doc/faqs.js" /* webpackChunkName: "component---src-templates-doc-faqs-js" */),
  "component---src-templates-doc-payment-js": () => import("./../../../src/templates/doc/payment.js" /* webpackChunkName: "component---src-templates-doc-payment-js" */),
  "component---src-templates-doc-privacy-js": () => import("./../../../src/templates/doc/privacy.js" /* webpackChunkName: "component---src-templates-doc-privacy-js" */),
  "component---src-templates-doc-return-refund-js": () => import("./../../../src/templates/doc/returnRefund.js" /* webpackChunkName: "component---src-templates-doc-return-refund-js" */),
  "component---src-templates-doc-rules-js": () => import("./../../../src/templates/doc/rules.js" /* webpackChunkName: "component---src-templates-doc-rules-js" */),
  "component---src-templates-doc-shipping-js": () => import("./../../../src/templates/doc/shipping.js" /* webpackChunkName: "component---src-templates-doc-shipping-js" */),
  "component---src-templates-doc-termsofservices-js": () => import("./../../../src/templates/doc/termsofservices.js" /* webpackChunkName: "component---src-templates-doc-termsofservices-js" */),
  "component---src-templates-euro-index-tsx": () => import("./../../../src/templates/euro/index.tsx" /* webpackChunkName: "component---src-templates-euro-index-tsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-login-index-js": () => import("./../../../src/templates/login/index.js" /* webpackChunkName: "component---src-templates-login-index-js" */),
  "component---src-templates-login-new-password-js": () => import("./../../../src/templates/login/new-password.js" /* webpackChunkName: "component---src-templates-login-new-password-js" */),
  "component---src-templates-login-register-js": () => import("./../../../src/templates/login/register.js" /* webpackChunkName: "component---src-templates-login-register-js" */),
  "component---src-templates-login-register-success-js": () => import("./../../../src/templates/login/register-success.js" /* webpackChunkName: "component---src-templates-login-register-success-js" */),
  "component---src-templates-login-reset-password-js": () => import("./../../../src/templates/login/reset-password.js" /* webpackChunkName: "component---src-templates-login-reset-password-js" */),
  "component---src-templates-login-reset-request-sent-js": () => import("./../../../src/templates/login/reset-request-sent.js" /* webpackChunkName: "component---src-templates-login-reset-request-sent-js" */),
  "component---src-templates-lowest-price-tsx": () => import("./../../../src/templates/lowestPrice.tsx" /* webpackChunkName: "component---src-templates-lowest-price-tsx" */),
  "component---src-templates-merchant-list-js": () => import("./../../../src/templates/merchant-list.js" /* webpackChunkName: "component---src-templates-merchant-list-js" */),
  "component---src-templates-order-list-tsx": () => import("./../../../src/templates/order-list.tsx" /* webpackChunkName: "component---src-templates-order-list-tsx" */),
  "component---src-templates-outdoors-index-tsx": () => import("./../../../src/templates/outdoors/index.tsx" /* webpackChunkName: "component---src-templates-outdoors-index-tsx" */),
  "component---src-templates-outlets-js": () => import("./../../../src/templates/outlets.js" /* webpackChunkName: "component---src-templates-outlets-js" */),
  "component---src-templates-search-input-tsx": () => import("./../../../src/templates/searchInput.tsx" /* webpackChunkName: "component---src-templates-search-input-tsx" */),
  "component---src-templates-similar-tsx": () => import("./../../../src/templates/similar.tsx" /* webpackChunkName: "component---src-templates-similar-tsx" */),
  "component---src-templates-top-selling-tsx": () => import("./../../../src/templates/topSelling.tsx" /* webpackChunkName: "component---src-templates-top-selling-tsx" */)
}

