import React, { useEffect, useState } from 'react';
import * as Style from './department.module.scss';
import { normalizeTerm, isBrowser } from '@/lib/utils';
import { WOMEN, MEN, BEAUTY, SPORTS_OUTDOOR, JEWELRY, PRE_LOVED, findCategoryRouteById } from '@/lib/categoryUtils';
import { useMediaQuery } from 'react-responsive';

const Department = () => {
  const [showDepartment, setShowDepartment] = useState(false)
  const isMobileScreen = useMediaQuery({
    query: "(max-width: 960px)",
  })
  useEffect(() => {
    if (!isBrowser) {
      return
    }
    setShowDepartment(!isMobileScreen)
  }, [isMobileScreen])
  if (!showDepartment) return null

  const renderTerm = (list) => {
    return list?.map((cate, index) => {
      const { id, query } = cate
      const route = findCategoryRouteById(id)
      if (!route && !query) return null
      const url = !route?.path ? `/searchList/?${query}` : `/category/${normalizeTerm(route.path)}/`
      const term = route?.term || cate?.term
      return (
        <div className={cate.children?.length ? Style.cateItem : Style.text} key={`k_${route?.id || ''}_${index}`}>
          <a href={url} aria-label={term}>
            <div className={cate.children?.length ? Style.title4 : Style.text}>
              {term}
            </div>
          </a>
          {
            !!cate.children?.length ? renderTerm(cate.children) : null
          }
        </div>
      )
    })
  }

  return (
    <div
      className={Style.pDepartment} 
      onMouseMove={(e) => { 
        e.preventDefault() 
        e.stopPropagation()
      }}
    >
      {
        [WOMEN, MEN].map((category, index) => {
          const route = findCategoryRouteById(category.id)
          if (!route) return null
          const url = `/category/${normalizeTerm(route.path)}/`
          return (
            <div className={Style.catePanel} key={`k_${route.id}_${index}`}>
              <a href={url}>
                <div className={Style.title3}>{route.term}</div>
                <div className={Style.cateChild}>
                  <div className={Style.top2}>
                    <div>
                      {renderTerm(category.children.slice(0, 2))}
                    </div>
                    <div>
                      {renderTerm(category.children.slice(2))}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          )
        })
      }
      <div className={Style.threeColumn}>
        {
          [SPORTS_OUTDOOR, BEAUTY].map((category, index) => {
            const route = findCategoryRouteById(category.id)
            if (!route) return null
            const url = `/category/${normalizeTerm(route.path)}/`
            return (
              <div className={Style.catePanel} key={`k_${route.id}_${index}`}>
                <a href={url}>
                  <div className={Style.title3}>{route.term}</div>
                  <div>
                    <div className={Style.top2}>
                      {renderTerm(category.children)}
                    </div>
                  </div>
                </a>
              </div>
            )
          })
        }
      </div>
      <div className={Style.fourColumn}>
        {
          [JEWELRY, PRE_LOVED].map((category, index) => {
            const route = category.id ? findCategoryRouteById(category.id) : category
            if (!route && !category.query) return null
            const url = !!route?.path ? `/category/${normalizeTerm(route.path)}/` : `/searchList?${category.query}`
            return (
              <div className={Style.catePanel} key={`k_${route.id || ''}_${index}`}>
                <a href={url}>
                  <div className={Style.title3}>{route.term}</div>
                  <div>
                    <div className={Style.top2}>
                      {renderTerm(category.children)}
                    </div>
                  </div>
                </a>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Department
