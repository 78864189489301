import React from "react";
import { isBrowser } from "@/lib/utils"

const LOWEST_PRICE_TIP = "NEW_TIPS";

const LOWEST_PRICE_PAGE_NAME = "LOWEST_PRICE";

const SPORTS_OUTDOORS_TIP = "SPORTS_OUTDOORS_TIP";

const SPORTS_OUTDOORS_PAGE_NAME = "SPORTS_OUTDOORS";

const TOP_SELLING_TIP = "TOP_SELLING_TIP";

const TOP_SELLING_PAGE_NAME = "TOP_SELLING";

export type Pages = typeof LOWEST_PRICE_PAGE_NAME | typeof SPORTS_OUTDOORS_PAGE_NAME | typeof TOP_SELLING_PAGE_NAME

export default function useTips(page: Pages) {
  const [pageName, setPageName] = React.useState<Pages | undefined>();

  const checkTips = () => {
    if (!localStorage?.getItem(TOP_SELLING_TIP)) {
        setPageName(TOP_SELLING_PAGE_NAME);
        return;
      }
    if (!localStorage?.getItem(SPORTS_OUTDOORS_TIP)) {
      setPageName(SPORTS_OUTDOORS_PAGE_NAME);
      return;
    }
    if (!localStorage?.getItem(LOWEST_PRICE_TIP)) {
      setPageName(LOWEST_PRICE_PAGE_NAME);
    }
  };

  React.useEffect(() => {
    if (!isBrowser) {
      return;
    }
    checkTips();
  }, [page]);

  const onPageVisible = React.useCallback((pageName: Pages) => {
    const mark = Number(new Date()).toString();
    if (pageName === TOP_SELLING_PAGE_NAME) {
      localStorage?.setItem(TOP_SELLING_TIP, mark);
    } else if (pageName === LOWEST_PRICE_PAGE_NAME) {
      localStorage?.setItem(LOWEST_PRICE_TIP, mark);
    } else if (pageName === SPORTS_OUTDOORS_PAGE_NAME) {
      localStorage?.setItem(SPORTS_OUTDOORS_TIP, mark);
    }
    checkTips();
  }, []);

  return [pageName, onPageVisible] as const;
}