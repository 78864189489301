import React, { MouseEventHandler, useState } from 'react';
import Mask from '@modules/common/Mask';
import * as mms from './menu_m.module.scss';
import LogoBlack from '@images/ic_logo_black.svg';
import icClose from '@images/icon_close_whitebg.png';
import icFilter from '@images/icon_filter_down.png';
import SearchBox from '@modules/category/searchBox';
import { isLogin } from '@service/AccountService';
import IcMore from '@images/icon_more.png';
import IcLess from '@images/icon_less.png';
import { normalizeTerm } from '@lib/utils';
import useTips, { Pages } from '@/hooks/use-tips';
import { WOMEN, MEN, BEAUTY, SPORTS_OUTDOOR, JEWELRY, BABY, findCategoryRouteById } from '@/lib/categoryUtils';

interface IProps {
  visible: boolean
  close: MouseEventHandler<HTMLDivElement>
  clickMenu: Function
  headerHeight: number
  pageName?: string
}

interface IOpenList {
  [k: string]: boolean
}

const modeList = [{
    title: 'B-OUTLET',
    path: '/outlet/',
}, {
    title: 'B-EURO',
    path: '/euro/',
}, {
    title: 'SPORTS & OUTDOORS',
    path: '/sports-outdoors/',
}, {
    title: 'TOP SELLING',
    path: '/top-selling/',
}, {
    title: 'LOWEST PRICE',
    path: '/lowest-price/',
}]

const otherList = [
  {
    title: 'Shop by Merchant',
    path: '/merchants/',
  },
  {
    title: 'Shop by Brand',
    path: '/brands/',
  },
  {
    title: 'Shop by Category',
    path: '/categories/',
  }
]

const Menu = (props: IProps) => {
  const { clickMenu, close, pageName } = props || {}
  const [openList, changeOpenList] = useState<IOpenList>({'8,e0,k1': true, '9,e1,k0': true, '7': true})

  const [tipPage, onPageVisible]  = useTips(pageName as Pages)

  function handleCreareAccount(e: any) {
    if (close) {
      close(e)
    }
    window.location.href = '/register'
  }

  const fold = (id: string) => {
    changeOpenList({ ...openList, [id]: !openList[id]})
  }

  const renderCate = (children: Array<any>, show?: boolean) => {
    return children?.map((category, i) => {
      const route = findCategoryRouteById(category.id)
      if (!route) return null
      const onClick = (e: any) => {
        e.preventDefault()
        clickMenu(`/category/${normalizeTerm(route.path)}/`)
      }

      const openKey = route.id || route.term
      return (
        <div key={i+'_category_item_' + route.id} className={show ? mms.childItem : mms.childItemNone}>
          <a 
            className={mms.flexBetweenChild}
            href={!!route.path ? `/category/${normalizeTerm(route.path)}/` : ''}
            onClick={onClick}
          >
            <span className={mms.categoryItem}>
              {route.term}
            </span>
            {
              category.children?.length
                ? <img 
                    src={openList[openKey] ? IcLess : IcMore} 
                    alt="menu item filter icon" 
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      fold(openKey)
                    }}
                  />
                : <img src={icFilter} alt="filter arrow" />
            }
          </a>
          {
            renderCate(category.children, openList[openKey])
          }
        </div>
      )
    })
  }
  
  return (
    <Mask visible={props.visible} close={close}>
      <>
        <div className={`${mms.lMenuM} ${props.visible ? mms.lMenuMShow : ''}`}>
          <div className={mms.menuMBg} onClick={close}></div>
          <div className={mms.headerBg}>
            <div className={mms.headerM}>
              <div className={mms.headerMImg} onClick={close}></div>
              <div className={mms.headerTitle}>
                <a href='/'><img onClick={close} src={LogoBlack} className={mms.logo} title='logo'></img></a>
              </div>
              <div className={mms.headerMProfile}></div>
            </div>

            <SearchBox />
          </div>
          <div className={mms.menuContainer}>
            {!isLogin() ? <div className={mms.create} onClick={handleCreareAccount}>Create an Account</div> : null}
            {
              modeList.map((menu, index) => {
                const isLowestPrice = menu.title === 'LOWEST PRICE';
                const isSportsOutdoors = menu.path === "/sports-outdoors/";
                const isTopSelling = menu.title === 'TOP SELLING';
                const onClick = (e: any) => {
                  e.preventDefault()
                  clickMenu(menu.path)
                  if(isLowestPrice) {
                    return onPageVisible("LOWEST_PRICE");
                  }
                  if(isSportsOutdoors) {
                    return onPageVisible("SPORTS_OUTDOORS")
                  }
                  if(isTopSelling) {
                    return onPageVisible("TOP_SELLING")
                  }
                }
                return (
                  <a
                    href={menu.path}
                    key={"menu_" + index}
                    className={mms.flexBetween}
                    onClick={onClick}
                  >
                    <h3>
                      {menu.title}
                      {((isSportsOutdoors && tipPage === "SPORTS_OUTDOORS") ||
                        (isLowestPrice && tipPage === "LOWEST_PRICE") ||
                        (isTopSelling && tipPage === "TOP_SELLING")) && (
                        <div className={mms.newTips}>NEW</div>
                      )}
                    </h3>
                    <img src={icFilter} alt="filter arrow" />
                  </a>
                );
              })
            }

            <div>
              <div className={mms.flexBetween}>
                <h3>Shop by Department</h3>
              </div>
              {
                renderCate([WOMEN, MEN, SPORTS_OUTDOOR, BEAUTY, JEWELRY, BABY], true)
              }
            </div>

            {
              otherList.map((menu, index) => {
                const onClick = (e: any) => {
                  e.preventDefault()
                  clickMenu(menu.path)
                }
                return (
                  <a
                    href={menu.path}
                    key={'menu_' + index} 
                    className={mms.flexBetween}
                    onClick={onClick}
                  >
                    <h3>{menu.title}</h3>
                    <img src={icFilter} alt="filter arrow" />
                  </a>
                )
              })
            }

            <a href="mailto:contact@beyondstyle.us">
              <div className={mms.flexBetween}>
                <h3>Contact US</h3>
                <img src={icFilter} alt="filter arrow" />
              </div>
            </a>
          </div>
        </div>
        <img className={mms.close} src={icClose} alt="close menu" onClick={close} />
      </>
    </Mask>
  )
}

export default Menu