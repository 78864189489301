import React, { useRef, useState, useEffect } from 'react';
import * as hms from './header_m.module.scss';
import LogoBlack from '@images/ic_logo_black.svg';
import LogoB from '@images/logo_b.svg';
import Menu from './MenuM';
import Profile from './ProfileM';
import { isLogin } from '@/service/AccountService';
import SearchIcon from '@images/ic_search.svg';
import SearchInput from '@/modules/search/searchInput';
import { isBrowser } from '@/lib/utils';
import { useMediaQuery } from 'react-responsive';
import useStickyHeader from '@/hooks/use-sticky-header'

const pageClassMap = new Map([['OUTLET', hms.pOutlets], ['ECO', hms.pEco]])

const searchIconVisiblePaths = [
  '/prod/', '/s/', '/brand/', '/store/', '/euro/', '/similar/', 
  '/lowest-price/', '/c/', '/sports-outdoors/', '/top-selling/',
  '/category/',
]

function isSearchIconVisible(pathname) {
  if (!pathname) {
    return false
  }
  if (searchIconVisiblePaths.some(path => pathname.startsWith(path))) {
    return true
  }
  return false
}

const HeaderM = (props) => {
  const { pageName, pathname } = props || {}
  const [visible, setVisible] = useState(false)
  const [visibleAvatar, setVisibleAvatar] = useState(false)
  const headerRef = useRef(null)
  const [searchInputVisible, setSearchInputVisible] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false);
  const [searchBtnVisible] = useState(() => isSearchIconVisible(pathname));
  const isCheckout = (pathname.indexOf('/checkout/') !== -1)
  const [fixed] = useStickyHeader(true, headerRef)
  const isMobileScreen = useMediaQuery({
    query: "(max-width: 960px)",
  })

  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    setMenuVisible(isMobileScreen);
  }, [isMobileScreen]);

  const getPageClass = () => {
    return pageClassMap.get(pageName) || ''
  }

  const clickAvatar = () => {
    if(isLogin()) {
      // 显示个人信息框
      setVisibleAvatar(true)
    } else {
      // 跳转登陆
      window.location.href = '/login'
    }
  }

  function clickMenu (path) {
    setVisible(false)
    if (path) {
      window.location.href = path
    }
  }

  function clickSearchInput(e) {
    e.preventDefault()
    setSearchInputVisible(true)
  }

  return (
    <header id='header_mobile' className={`${getPageClass()} ${hms.headerMobile}`} ref={headerRef}>
      <div className={`${hms.headerM} ${fixed ? hms.fixed : ''}`}>
        {isCheckout ? <div className={hms.headerLeftPlaceholder}></div> : <div className={hms.headerMImg} onClick={() => { setVisible(!visible) }}></div>}
        {
          searchBtnVisible || fixed ? (
            <a href='/search' key='search-input' title='search-input' className={hms.searchGrey} onClick={clickSearchInput}>
              <img src={SearchIcon} alt='search-icon' />
            </a>
          ) : null
        }
        {isCheckout ? (
          <div className={hms.checkoutHeaderTitle}>
            <img src={LogoB} />Check Out
          </div>
        ): (
          <div className={hms.headerTitle}>
            <a href='/' aria-label='homepage' title='homepage'>
                <img className={hms.logo} alt='logo' src={LogoBlack} />
            </a>
          </div>
        )}
        <div onClick={clickAvatar} className={hms.headerMProfile} title="profile icon" ></div>
      </div>

      {menuVisible ? (
        <Menu pageName={pageName} clickMenu={clickMenu} visible={visible} headerHeight={headerRef?.current?.offsetHeight} close={() => setVisible(false)} />
      ) : null}
      <Profile visible={visibleAvatar} close={() => setVisibleAvatar(false)} />
      <SearchInput visible={searchInputVisible} setVisible={setSearchInputVisible} />
    </header>
  )
}

export default HeaderM