import React, { useState } from 'react';
import * as fms from './footer_m.module.scss';
import ArrowDown from '@images/icon_down.png';
import icTiktok from '@images/icon_tiktok.png';
import icIns from '@images/icon_ins.png';
import icFace from '@images/icon_facebook.png';
import icPin from '@images/ic_pinterest.png';

const footTab = [
  {
    ID: 'CUSTOMER SERVICE',
    title: 'SUPPORT & SERVICE',
    items: [
      { title: 'Coupons & Deals', url: '/deals/' },
      { title: 'Categories', url: '/categories/' },
      { title: 'Brands', url: '/brands/' },
      { title: 'Merchants', url: '/merchants/' },
      { title: 'Articles', url: '/articles/' },
      { title: 'FAQs', url: '/faqs/' },
      { title: 'Affiliates', url: '/affiliates/' },
      { title: 'Contact', url: '/contactus/' },
    ]
  },
  {
    ID: 'INFO & POLICY',
    title: 'INFO & POLICY',
    items: [
      { title: 'Privacy Policy', url: '/privacy/' },
      { title: 'Terms of Service', url: '/termsofservices/' },
      { title: 'Shipping Policy', url: '/shipping-policy/' },
      { title: 'Payment Policy', url: '/payment-policy/' },
      { title: 'Return / Refund', url: '/returnRefund/' },
      { title: 'Disclosure', url: '/disclosure/' },
      { title: 'Become a Partner', url: 'http://www.nubestore.ai/#/contact-us', target: '_blank' },
      { title: 'About Us', url: '/aboutus/' },
    ]
  }
]

const pageClassMap = new Map([['OUTLET', fms.pOutlets], ['ECO', fms.pEco]])

const FooterM = ({ pageName }) => {
  const [current, setCurrent] = useState('')

  const getPageClass = () => {
    return pageClassMap.get(pageName) || ''
  }

  const changeCurrent = (value) => {
    setCurrent(current === value ? '' : value)
  }

  return (
    <>
      <div className={`${fms.footerM} ${getPageClass()}`}>
        {
          footTab.map(item => {
            return (
              <div key={'foot_' + item.ID} className={fms.fM}>
                <div className={fms.fMT} onClick={() => changeCurrent(item.ID)}>
                  <h3>{item.title}</h3>
                  <img className={current === item.ID ? fms.turnDown : ''} src={ArrowDown} alt="title icon" />
                </div>
                <div className={`${fms.fMContent}  ${current === item.ID ? fms.fMContentShow : ''}`}>
                  {
                    item.items.map(i => {
                      return <a href={i.url} aria-label={i.title} target={i.target || ''} rel={i.target ? 'noreferrer' : ''} key={'foot_' + item.ID + '_' + i.title}><div>{i.title}</div></a>
                    })
                  }
                </div>
              </div>
            )
          })
        }

        <div className={fms.fMT} onClick={() => changeCurrent('Follow')}>
          <h3>FOLLOW US</h3>
          <img className={current === 'Follow' ? fms.turnDown : ''} src={ArrowDown} alt="follow us arrow down icon" />
        </div>
        <div className={`${fms.fMContent}  ${current === 'Follow' ? fms.fMContentShow : ''}`}>
          <a href="https://www.instagram.com/beyondstyle.us/" target="_blank" rel="noreferrer" title='beyondstyle instagram'><img className={fms.iconb} src={icIns} alt="beyondstyle instagram" /></a>
          <a href="https://www.facebook.com/profile.php?id=100083188368188" target="_blank" rel="noreferrer" title='beyondstyle facebook'><img className={fms.iconb} src={icFace} alt="beyondstyle facebook" /></a>
          <a href="https://www.tiktok.com/@beyondstyle.us?is_copy_url=1&is_from_webapp=v1&lang=en" title='beyondstyle tiktok' target="_blank" rel="noreferrer"><img className={fms.iconb} src={icTiktok} alt="beyondstyle tiktok" /></a>
          <a href="https://www.pinterest.com/beyondstyle/" title='beyondstyle pinterest' target="_blank" rel="noreferrer"><img className={fms.iconb} src={icPin} alt="beyondstyle pinterest" /></a>
        </div>
        <div className={fms.cp}>Copyright © 2025 BorderX Lab All Rights Reserved.</div>
      </div>

      <div className={`${fms.footerPc} ${getPageClass()}`}>
        <div className={fms.fPcMain}>
          {
            footTab.map(item => {
              return (
                <div key={'foot_' + item.ID} className={fms.fM}>
                  <div className={fms.fMT}>
                    <h3>{item.title}</h3>
                  </div>
                  <div className={`${fms.fMContent}  ${fms.fMContentShow}`}>
                    {
                      item.items?.map(i => {
                        return <a title={i.title} aria-label={i.title} href={i.url} key={'foot_' + item.ID + '_' + i.title} target={i.target || ''}><div>{i.title}</div></a>
                      })
                    }
                  </div>
                </div>
              )
            })
          }

          <div className={fms.fM}>
            <div className={fms.fMT}>
              <h3>FOLLOW US</h3>
            </div>
            <div className={`${fms.fMContent}  ${fms.fMContentShow}`}>
              <a href="https://www.instagram.com/beyondstyle.us/" target="_blank" rel="noreferrer" aria-label='beyondstyle instagram'><img className={fms.iconb} src={icIns} alt="beyondstyle instagram" /></a>
              <a href="https://www.facebook.com/profile.php?id=100083188368188" target="_blank" rel="noreferrer" aria-label='beyondstyle facebook'><img className={fms.iconb} src={icFace} alt="beyondstyle facebook" /></a>
              <a href="https://www.tiktok.com/@beyondstyle.us?is_copy_url=1&is_from_webapp=v1&lang=en" target="_blank" rel="noreferrer" aria-label='beyondstyle tiktok'><img className={fms.iconb} src={icTiktok} alt="beyondstyle tiktok" /></a>
              <a href="https://www.pinterest.com/beyondstyle/" target="_blank" rel="noreferrer" aria-label='beyondstyle pinterest'><img className={fms.iconb} src={icPin} alt="beyondstyle pinterest" /></a>
            </div>
          </div>
        </div>
        <div className={fms.cp}>Copyright © 2025 BorderX Lab All Rights Reserved.</div>
      </div>
    </>
  )
}

export default FooterM