import React, { useState, useEffect, useContext } from 'react';
import './searchInput.scss';
import { SearchContext } from '@components/Context';
import Input from 'antd-mobile/es/components/input';
import iconSearchWhite from '@images/icon_search_white.svg';
import { getHotWords } from '@service/api';
import { isBrowser } from '@lib/utils';
import { trackEvent } from '@/lib/analytics';

function SearchTags(props: any) {
  const { title, items, onClickItem } = props || {}
  if (!title) return null
  return (
    <div className='search-popular'>
      <div className='popular-tit'>{title}</div>
      <div className='popular-items'>
        {
          items?.map((item: any, index: any) => {
            return (
              <a
                key={'search_' + item + index} 
                className='popular-item'
                href={`/searchList/?q=${encodeURIComponent(item)}`}
                onClick={(e: any) => onClickItem(e, item)}
              >
                {item}
              </a>
            )
          })
        }
      </div>
    </div>
  )
}

const SearchInput = (props: any) => {
  const { visible, setVisible } = props || {}
  const [hotWords, setHotWords] = useState([])
  const { updateQuery, query }: any = useContext(SearchContext)
  const [value, setValue] = useState(() => query || '')

  useEffect(() => {
    if (!isBrowser) return
    if (visible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [visible])

  useEffect(() => {
    if (!visible) return
    getHotWords()
      .then((res: any) => {
        setHotWords(res.hotWords)
      })
      .catch(() => {
        // console.warn(err)
      })
  }, [visible])

  const doSearch = () => {
    document.body.style.overflow = "auto"
    setVisible(false)
    updateQuery(value)
    trackEvent('search_action', {
      event_category: 'search',
      event_action: 'search_input',
      search_term: value,
    })
  }

  const handleClickHotWord = (e: any, item: string) => {
    e.preventDefault()
    document.body.style.overflow = "auto"
    setValue(item)
    setVisible(false)
    updateQuery(item)
    trackEvent('search_action', {
      event_category: 'search',
      event_action: 'hot_words',
      search_term: item,
    })
  }

  if (!visible) return null

  return (
    <div className='p-search'>
      <div className='p-search-head' onClick={() => setVisible(false)} />
      <div className='p-search-body'>
        <div className='p-search-main'>
          <form target={'frameFile'} onSubmit={doSearch}>
            <Input
              placeholder={`Search for any product or brand`}
              value={value}
              className='search-input'
              autoFocus
              onChange={val => {
                setValue(val)
              }}
            />
            <iframe title='search-frame' name='frameFile' style={{ display: 'none' }}></iframe>
          </form>
          <div className='p-icon-search' onClick={doSearch}>
            <img src={iconSearchWhite} alt='search icon' />
          </div>

          <div 
            onClick={() => {
              setValue('')
              setVisible(false)
              // window.history.back()
            }}
          >
            Cancel
          </div>
        </div>

        <SearchTags title='Popular' items={hotWords} onClickItem={handleClickHotWord} />
      </div>
    </div>
  )
}

export default SearchInput