import React from "react"
import { Script } from "gatsby"
import Layout from "./src/layouts"
import ErrorBoundary from './src/components/errorBoundary';

function ErrorRootWrapper({ children }) {
  if (process.env.NODE_ENV === 'production') {
    return (
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    )
  }
  return children
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <ErrorRootWrapper>
        <Layout {...props}>
          {element}
        </Layout>
      </ErrorRootWrapper>
      {/* <Script id="fbevents-init">
        {`
           !function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
           n.queue=[];t=b.createElement(e);t.async=!0;
           t.src=v;s=b.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window, document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '1368641886933715');
           fbq('track', 'PageView');
        `}
      </Script> */}
      <Script id='imp-init'>
        {`(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).
        push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f
        .src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A5755410-d356-45db-a9e4-bf5503f9003c1.js',
        'script','ire',document,window);`}
      </Script>
    </>
  )
}

export function shouldUpdateScroll({ routerProps, getSavedScrollPosition }) {
  const { location } = routerProps
  const { action } = location || {}
  if (action === 'POP') {
    const currentPosition = getSavedScrollPosition(location)
    // return currentPosition || [0, 0]
    window.setTimeout(() => window.scrollTo(...(currentPosition || [0, 0])), 100)
  } else if (action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), 100)
  }
  return false
}
